import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import SpinToWin from 'src/components/SpinToWin'
import SEO from 'src/components/shared/SEO'
import benefitIcon from 'src/assets/img/spin-to-win/03/icon.svg'

const PRODUCT_CONFIG = {
  lookupKey: 'wheel-01',
  slug: 'digestive-probiotics',
  colors: {
    primary: '#EAE8FF',
    secondary: '#B6B6F2',
    ticker: '#a7a5de'
  },
  aboveTheFold: {
    wheelBaseSVG: null,
    verticalOrientation: false,
    copyOne: "It's Your Lucky Day!",
    copyTwo: 'Spin to Win',
    copyThree: 'Gift Cards & Free Products',
    prizeUrl: 'digestive-probiotics',
    promo: {
      code: 'SPIN20',
      autoApply: true,
      discount: '20%',
      component: null
    }
  },
  benefits: {
    title: 'For Healthy Poops, And Easy Scoops',
    items: [
      {
        label: '6 Strains of Living Probiotics',
        detail:
          '3 billion beneficial bacteria per chew that work to boost growth of good gut flora and stop the bad stuff — supporting a healthy gut microbiome & robust immune system.'
      },
      {
        label: 'Soothing Pumpkin',
        detail:
          "A natural stomach soother, pumpkin is a healthy source of fiber with prebiotic properties that help regulate your dog's digestive system -- providing relief from both diarrhea and constipation."
      },
      {
        label: 'Prebiotic \nSuperfoods',
        detail:
          "Probiotics need the right food -- and that's where Prebiotics come in. The Pumpkin, Inulin and FOS found in Finn help 'good' bacteria flourish and stay nourished."
      },
      {
        label: 'NASC Certified',
        detail:
          "Finn is proudly certified by the National Animal Supplement Council, demonstrating compliance with NASC's rigorous safety, quality, and control standards. We manufacture our products in a cGMP facility, right here in the USA."
      }
    ],
    icon: benefitIcon,
    collapseMinHeight: 100
  }
}

const WheelOne: React.FC<PageProps> = ({ location }) => {
  const { copyTwo, copyThree } = PRODUCT_CONFIG.aboveTheFold
  const metaTitle = `${copyTwo} - ${copyThree}`

  return (
    <>
      <SEO title={metaTitle} location={location} />

      <Layout location={location}>
        <SpinToWin details={PRODUCT_CONFIG} />
      </Layout>
    </>
  )
}

export default WheelOne
